import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import Dropzone from "react-dropzone";
import { sendInProgressMediaMessages } from "../CommonFunctions/CompressionImageAndCreateThumbnailVideo.js";
import ModelPopup from "../../../components/ModalPopup/ModalPopup.js";
import Typography from "../../../components/Typography/Typography.js";
import {
  addtyper,
  recivemessage,
  setLastMessage,
  editmessage,
} from "../../../Action/index";
import { formatBytes } from "../CommonFunctions/ConvertBytes.js";
import newsocket from "../../../components/SocketConnect.js";
import ChatMessageBar from "../Components/ChatMessageBar.js";
import authService from "../../../Services/auth.service.js";

export default function ChatMessageBarContent({
  replyMessageData,
  setReplyMessageData,
  allChatHeaderDetail,
  seteditMessage,
  editMessage,
  msg,
  setMsg,
  setSelectedMentionName,
  selectedMentionName,
  setAttachmentData,
  handleClickOnRepliedMessage = () => { },
  handlePreviewClick,
  drag,
  setdrag,
  groupMemberList,
  editMessageState,
  setEditMessageState,
  editorClear,
  setEditorClear,
  setGroupMemberList
}) {
  const ref = useRef();
  const userId = localStorage.getItem("userid");
  // const sendBtnClick = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const chatList = useSelector((state) => {
    return state.ReduAllChatList;
  });
  const allMessages = useSelector((state) => {
    return state.ReduChat;
  });
  const [allUsersList, setAllUsersList] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [hasMentionDropdown, setHasMentionDropdown] = useState(false);
  const [filteredSuggestionList, setFilteredSuggestionList] = useState([]);
  const [isemojiOpenSelect, setisemojiSelect] = useState(false);
  const [isTyping, setisTyping] = useState(false);
  const [debounceTimer, setdebounceTimer] = useState("");
  const [files, setFiles] = useState({
    images: [],
    videos: [],
    files: [],
    voice: [],
  });
  const [filesURL, setFilesURL] = useState({
    images: [],
    videos: [],
    files: [],
    voice: [],
  });
  const [hasEmojiDropdown, setHasEmojiDropdown] = useState(false);

  const { userid, type } = useParams();
  const [mentionDropDownTopRight, setMentionDropDownTopRight] = useState({
    top: "",
    right: "",
  }); // display mention drop down with dynamic top
  const [currentFormattedSendMessage, setCurrentFormattedSendMessage] =
    useState({});
  const [isVoiceMessageSelected, setIsVoiceMessageSelected] = useState(false);
  const [audioBlobData, setAudioBlobData] = useState();
  const [recordingDuration, setRecordingDuration] = useState();
  const [uploadErr, setuploadErr] = useState("");
  const [uploadErrFlag, setuploadErrFlag] = useState(false);
  const [sendBtnClick, setSendBtnClick] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [hideDefault, setHideDefault] = useState(true);

  const alltyper = useSelector((state) => {
    return state.ReduTypers;
  });

  const sendloader = useSelector((state) => {
    return state.ReduSendMsgLoader;
  });

  const inProgressMediaMessage = useSelector((state) => {
    return state.reducerInProgressMediaMessage;
  });

  const chatAllUsers = useSelector((state) => state.ReduAllUsersList);

  useEffect(() => {
    setSuggestionList(chatAllUsers);
  }, [chatAllUsers]);

  useEffect(() => {
    ref.current = { userid: userid, type: type };
    setReplyMessageData({});
    setSelectedMentionName([]);
    setHasMentionDropdown(false);
    setFiles({ images: [], videos: [], files: [] });
    setFilesURL({ images: [], videos: [], files: [] });
    setAttachmentData({ images: [], videos: [] });
    setMsg("");
    setMentionDropDownTopRight({ top: "", right: "" });
  }, [userid, type]);

  useEffect(() => {
    newsocket.on("typing", (data) => {
      dispatch(addtyper(data));
    });
    authService.getAllValidUserAndAttendees().then((response) => {
      if (response?.data?.status) {
        setAllUsersList(response?.data?.data);
      }
    });
  }, []);

  useEffect(() => {
    let firstName = "";
    let lastName = "";
    if (
      type === "user" &&
      allChatHeaderDetail[userid] &&
      allChatHeaderDetail[userid]
    ) {
      firstName = allChatHeaderDetail[userid].first_name
        ? allChatHeaderDetail[userid].first_name
        : "";

      lastName = allChatHeaderDetail[userid].last_name
        ? allChatHeaderDetail[userid].last_name
        : "";
    }
    const formattedData = {
      message: "",
      recipient_type: type === "user" ? "airtable-syncs" : type,
      sender_type: "airtable-syncs",
      recipient: {
        id: userid,
        firstname:
          type !== "userChatGroup" &&
            type !== "chatChannel" &&
            allChatHeaderDetail[userid]
            ? firstName + " " + lastName
            : type.toLowerCase() === "userchatgroup" && groupMemberList
              ? groupMemberList?.groupTitle
              : type.toLowerCase() === "chatchannel" && groupMemberList
                ? groupMemberList?.channelData?.channelName
                : "",
        image:
          type !== "userChatGroup" &&
            type !== "chatChannel" &&
            allChatHeaderDetail[userid]
            ? allChatHeaderDetail[userid].profileImg &&
              allChatHeaderDetail[userid].profileImg.length > 0
              ? allChatHeaderDetail[userid].profileImg
              : ""
            : type !== "chatChannel"
              ? allChatHeaderDetail[userid] &&
                allChatHeaderDetail[userid].groupImage &&
                allChatHeaderDetail[userid].groupImage.length > 0
                ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                allChatHeaderDetail[userid].groupImage
                : ""
              : allChatHeaderDetail[userid] &&
                allChatHeaderDetail[userid].channelIcon &&
                allChatHeaderDetail[userid].channelIcon.length > 0
                ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                allChatHeaderDetail[userid].channelIcon
                : "",
        type: type,
      },
      sender: {
        id: localStorage.getItem("userid"),
        firstname: localStorage.getItem("username"),
        image: localStorage.getItem("userprofile"),
        type: "user",
      },
      type: type,
      isActive: true,
      media: [],
      otherfiles: [],
      readmsg: false,
      group_member:
        type.toLowerCase() === "chatchannel" &&
          groupMemberList &&
          groupMemberList.membersList
          ? groupMemberList.membersList
            .filter((membr) => {
              if (membr && membr._id && membr.user_type !== "adminuser") {
                return membr;
              }
            })
            .map((member) => {
              if (member._id.toString() === localStorage.getItem("userid"))
                return {
                  id: member._id,
                  readmsg: true,
                };
              else
                return {
                  id: member._id,
                  readmsg: false,
                };
            })
          : type.toLowerCase() === "userchatgroup" &&
            groupMemberList &&
            groupMemberList.members
            ? groupMemberList.members.map((member) => {
              if (member?.userId?.toString() === localStorage.getItem("userid"))
                return {
                  id: member?.userId,
                  readmsg: true,
                };
              else
                return {
                  id: member?.userId,
                  readmsg: false,
                };
            })
            : [
              {
                id: userid,
                readmsg: false,
              },
              {
                id: localStorage.getItem("userid"),
                readmsg: true,
              },
            ],
      time: "",
      date: "",
      quote_message_id: null,
      isBlock: false,
      isLink: false,
      edited: false,
      activity_status: false,
      message_type: "",
      video_thumbnail: "",
      createdBy: null,
      taggedUserId: [],
      userTimeStamp: "",
    };
    setCurrentFormattedSendMessage(formattedData);
  }, [allChatHeaderDetail, groupMemberList]);

  const toChangeCompresstoProgress = (id, frontendUniqueId) => {
    dispatch({
      type: "CHANGEPROGRESSSTATUS",
      payload: {
        id: id,
        frontendUniqueId: frontendUniqueId,
        progressStatus: "Processing...",
      },
    });
    dispatch({
      type: "ASSIGNPROGRESSMEDIAMESSAGE",
      payload: {
        message: "Uploading in progress...",
        isUploading: true,
        currentChatId: userid,
      },
    });
  };

  const processInProgressMediaMessages = () => {
    inProgressMediaMessage.map(async (data) => {
      const UploadMediaDatas = await sendInProgressMediaMessages(
        data,
        toChangeCompresstoProgress
      );
      let payload = {
        ...data,
        isWeb: true,
        images:
          UploadMediaDatas.uploadedImages.length > 0
            ? UploadMediaDatas.uploadedImages
            : [],
        videos:
          UploadMediaDatas.uploadedVideos.length > 0
            ? UploadMediaDatas.uploadedVideos
            : [],
        documents:
          UploadMediaDatas.uploadedDocs.length > 0
            ? UploadMediaDatas.uploadedDocs
            : [],
        voiceNotes:
          UploadMediaDatas.uploadedVoiceNotes.length > 0
            ? UploadMediaDatas.uploadedVoiceNotes.map((voice) => {
              return voice.url;
            })
            : [],
        formattedData: {
          ...data.formattedData,
          images:
            UploadMediaDatas.uploadedImages.length > 0
              ? UploadMediaDatas.uploadedImages
              : [],
          videos:
            UploadMediaDatas.uploadedVideos.length > 0
              ? UploadMediaDatas.uploadedVideos
              : [],
          documents:
            UploadMediaDatas.uploadedDocs.length > 0
              ? UploadMediaDatas.uploadedDocs
              : [],
          voiceNotes:
            UploadMediaDatas.uploadedVoiceNotes.length > 0
              ? UploadMediaDatas.uploadedVoiceNotes.map((voice) => {
                return voice.url;
              })
              : [],
        },
      };
      newsocket.emit("new-send-latest", payload);
      dispatch({
        type: "REMOVEINPROGRESSMESSAGE",
        payload: payload.frontendUniqueId,
      });
      dispatch({
        type: "ASSIGNPROGRESSMEDIAMESSAGE",
        payload: {
          message: "",
          isUploading: false,
          currentChatId: null,
        },
      });
    });
  };

  useEffect(() => {
    processInProgressMediaMessages();
  }, [inProgressMediaMessage]);

  let typingTimeout = null;

  const handleCallTyping = () => {
    if (!isTyping) {
      setisTyping(true);
      newsocket.emit("typing", {
        recipient:
          ref.current !== null && ref.current !== undefined
            ? ref.current.userid
            : "",
        sender: localStorage.getItem("userid"),
        type:
          ref.current !== null && ref.current !== undefined
            ? ref.current.type
            : "",
        sendername: localStorage.getItem("username"),
      });
    }
    // if (debounceTimer) clearTimeout(debounceTimer);

    // setdebounceTimer(
    //   setTimeout(() => {
    //     handleCallTypingOff();
    //   }, 5000)
    // );

    resetTypingTimeout();
  };

  // Function to reset the typing timeout
  const resetTypingTimeout = () => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    typingTimeout = setTimeout(() => {
      handleCallTypingOff();
    }, 5000);
  };

  //typing-off event call
  const handleCallTypingOff = () => {
    setisTyping(false);
    newsocket.emit("typingoff", {
      recipient:
        ref.current !== null && ref.current !== undefined
          ? ref.current.userid
          : "",
      sender: localStorage.getItem("userid"),
      type:
        ref.current !== null && ref.current != undefined
          ? ref.current.type
          : "",
      sendername: localStorage.getItem("username"),
    });
  };

  const sendEmitEvent = (
    text = "",
    media = undefined,
    urlLen,
    taggedUserList = [],
    timeStampNowStore
  ) => {
    let uniqueId = uuidv4();
    let timeStampNow =
      timeStampNowStore ??
      moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]").toString();
    let formattedTaggedUsersList = taggedUserList.map((taguser) => {
      return allUsersList.filter((user) => {
        if (user._id.toString() === taguser) return user;
      })[0];
    });

    let msgType = "";
    if (text.length > 0) msgType = "text";
    if (urlLen > 0) msgType = msgType.length > 0 ? `${msgType},url` : "url";
    if (media.documents.length > 0)
      msgType = msgType.length > 0 ? `${msgType},document` : "document";
    if (media.videos.length > 0)
      msgType = msgType.length > 0 ? `${msgType},video` : "video";
    if (media.images.length > 0)
      msgType = msgType.length > 0 ? `${msgType},image` : "image";
    if (media.voiceNotes.length > 0)
      msgType = msgType.length > 0 ? `${msgType},voice` : "voice";
    const formattedData = {
      ...currentFormattedSendMessage,
      message: text,
      quote_message_id: replyMessageData._id
        ? { ...replyMessageData, _id: replyMessageData._id.slice(0, -1) }
        : null,
      isBlock: false,
      isLink: false,
      edited: false,
      activity_status: false,
      message_type: msgType,
      createdBy: null,
      taggedUserId: formattedTaggedUsersList,
      userTimeStamp: timeStampNow,
      frontendUniqueId: uniqueId,
    };
    let payload = {
      message: text,
      recipient: userid,
      sender: localStorage.getItem("userid"),
      type: type,
      images: media.images.length > 0 ? media.images : [],
      videos: media.videos.length > 0 ? media.videos : [],
      documents: media.documents.length > 0 ? media.documents : [],
      voiceNotes: media.voiceNotes.length > 0 ? media.voiceNotes : [],
      sender_name: localStorage.getItem("username"),
      quotemsg: replyMessageData._id
        ? replyMessageData._id.slice(0, -1)
        : undefined,
      group_member:
        type.toLowerCase() === "chatchannel" &&
          groupMemberList &&
          groupMemberList.membersList
          ? groupMemberList.membersList
            .filter((membr) => {
              if (membr && membr._id && membr.user_type !== "adminuser") {
                return membr;
              }
            })
            .map((member) => {
              if (member._id.toString() === localStorage.getItem("userid"))
                return {
                  id: member._id,
                  readmsg: true,
                };
              else
                return {
                  id: member._id,
                  readmsg: false,
                };
            })
          : type.toLowerCase() === "userchatgroup" &&
            groupMemberList &&
            groupMemberList.members
            ? groupMemberList.members.map((member) => {
              if (member?.userId?.toString() === localStorage.getItem("userid"))
                return {
                  id: member?.userId,
                  readmsg: true,
                };
              else
                return {
                  id: member?.userId,
                  readmsg: false,
                };
            })
            : [
              {
                id: userid,
                readmsg: false,
              },
              {
                id: localStorage.getItem("userid"),
                readmsg: true,
              },
            ],
      message_type: msgType,
      isWeb: true,
      taggedUserId: taggedUserList,
      time_stamp: timeStampNow,
      formattedData: formattedData,
      firstTime: chatList
        ? chatList.filter((list) => {
          if (list.id === userid) return list;
        }).length > 0
          ? false
          : true
        : true,
      frontendUniqueId: uniqueId,
    };
    if (
      media.images.length === 0 &&
      media.videos.length === 0 &&
      media.documents.length === 0 &&
      media.voiceNotes.length === 0
    ) {
      dispatch(recivemessage([formattedData]));
      newsocket.emit("new-send-latest", payload);
      dispatch(setLastMessage({ message: formattedData, currentPage: true }));
    } else {
      dispatch({ type: "ADDINPROGRESSMESSAGE", payload: payload });
      dispatch({
        type: "ASSIGNPROGRESSMEDIAMESSAGE",
        payload: {
          message: `${media.images.length || media.videos.length
            ? "Compressing..."
            : "Proccesing..." + "in progress..."
            }`,
          isUploading: true,
          currentChatId: userid,
        },
      });

      dispatch(
        recivemessage([
          {
            ...formattedData,
            message_type: "processing",
            progressStatus:
              media.images.length || media.videos.length
                ? "Compressing..."
                : "Proccesing...",
          },
        ])
      );
    }
  };

  const handleEnter = async (e, sendButtonClick = false) => {
    // sendBtnClick;
    setSendBtnClick(true);
    let urlLen = 0,
      updatedText = "",
      filteredList = [];
    let timeStampNowStore = moment
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]")
      .toString();
    setHasEmojiDropdown(false);
    if ((e && e.keyCode == 13) || sendButtonClick) {
      const chat = document.getElementById("NEW_MAIN_CHAT_CONTAINER");
      chat.scrollTop = chat.scrollHeight;

      if (e) {
        e.preventDefault();
      }
      updatedText = msg.trim();
      if (!sendloader) {
        handleCallTypingOff();
        if (
          !editMessage.status &&
          (updatedText.length > 0 ||
            (files &&
              (files.images.length ||
                files.videos.length ||
                files.files.length ||
                audioBlobData)))
        ) {
          if (updatedText.length > 0) {
            urlLen = updatedText.split(" ").filter((links) => {
              if (
                new RegExp(
                  "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
                ).test(links)
              )
                return links;
            }).length;
            updatedText = updatedText;
            filteredList = [];
            if (updatedText.includes("@")) {
              updatedText.split(/\s+/).forEach((item) => {
                if (item.startsWith("@")) {
                  let match = item.substring(1);
                  if (match.length) {
                    filteredList.push(match);
                  }
                }
              });
              updatedText = updatedText
                .replace(/\[.*?\]/g, "")
                .replace(/\(/g, "")
                .replace(/\)/g, "")
            }
          }
          updatedText = updatedText.trim();
          console.log(updatedText, "updatedText");

          sendEmitEvent(
            updatedText,
            {
              images: files.images,
              videos: files.videos,
              documents: files.files,
              voiceNotes: audioBlobData ? [audioBlobData] : [],
            },
            urlLen,
            filteredList,
            timeStampNowStore
          );
          setFiles({ images: [], videos: [], files: [] });
          setFilesURL({ images: [], videos: [], files: [] });
          setAttachmentData({ images: [], videos: [] });
          setAudioBlobData();
          setRecordingDuration();
          setMsg("");
          setReplyMessageData({});
        } else if (editMessage.status && updatedText.trim().length > 0) {
          // updatedText = text;
          filteredList = [];
          if (updatedText.includes("@")) {
            updatedText.split(" ").map((item) => {
              if (item.startsWith("@")) {
                let match = item.substring(1);
                if (match.length) {
                  filteredList.push(match);
                }
              }
            });
            updatedText = updatedText
              .replace(/\[.*?\]/g, "")
              .replace(/\(/g, "")
              .replace(/\)/g, "");
          }
          let formattedTaggedUsersList = filteredList.map((taguser) => {
            return allUsersList.filter((user) => {
              if (user._id.toString() === taguser) return user;
            })[0];
          });
          newsocket.emit("edit", {
            messageId: editMessage.id,
            message: updatedText,
            recipient: userid,
            sender: localStorage.getItem("userid"),
            taggedUserId: filteredList,
            type: type,
          });
          let lastMessage =
            allMessages && allMessages[userid] ? allMessages[userid][0] : {};
          let getMessageFullData =
            allMessages && allMessages[userid]
              ? allMessages[userid].filter(
                (msg) => msg._id === editMessage.id
              )[0]
              : {};
          if (
            lastMessage &&
            getMessageFullData &&
            lastMessage._id === getMessageFullData._id
          ) {
            dispatch({
              type: "EDITMESSAGECHATLIST",
              payload: {
                id: userid,
                message: updatedText,
                taggedUserId: formattedTaggedUsersList,
              },
            });
          }

          dispatch(
            editmessage({
              id: userid,
              data: {
                ...getMessageFullData,
                message: updatedText,
                taggedUserId: formattedTaggedUsersList,
                edited: true,
              },
            })
          );
          setFiles({ images: [], videos: [], files: [] });
          setFilesURL({ images: [], videos: [], files: [] });
          setAttachmentData({ images: [], videos: [] });
          setMsg("");
          setAudioBlobData();
          setRecordingDuration();
          setReplyMessageData({});
          seteditMessage({
            status: false,
            msg: "",
            id: "",
            taggedId: [],
          });
        }
        newsocket.emit("typingoff", {
          recipient: userid,
          sender: localStorage.getItem("userid"),
          type: type,
          sendername: localStorage.getItem("username"),
        });
      }
    }
  };

  const getTextWidth = (text, element) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = window.getComputedStyle(element).getPropertyValue("font");
    return context.measureText(text).width;
  };
  const handleMentionDropDownPosition = (element) => {
    if (element.pageY === undefined) {
      const textarea = element.target;
      const cursorIndex = textarea.selectionStart;
      const cursorEndIndex = textarea.selectionEnd;
      // Get the textarea's bounding rectangle
      const textareaRect = textarea.getBoundingClientRect();

      // Calculate the x and y position of the cursor in screen coordinates
      const textBeforeCursor = textarea.value.substring(0, cursorIndex);
      const linesBeforeCursor = textBeforeCursor.split("\n");
      const lastLineBeforeCursor =
        linesBeforeCursor[linesBeforeCursor.length - 1];
      const lastLineBeforeCursorWidth = getTextWidth(
        lastLineBeforeCursor,
        textarea
      );

      const lineHeight = textarea.scrollHeight / textarea.rows;
      const numberOfLines = Math.round(textarea.scrollHeight / lineHeight);
      // Split the textarea content into an array of lines
      const contentLines = Array.from({ length: numberOfLines }, (_, index) => {
        const start = index * textarea.cols;
        const end = start + textarea.cols;
        return textarea.value.slice(start, end);
      });

      const xPosition = lastLineBeforeCursorWidth;
      const yPosition =
        (linesBeforeCursor.length - 1) *
        (textarea.scrollHeight / linesBeforeCursor.length);
      setMentionDropDownTopRight({ top: yPosition, right: xPosition });
    } else {
      setMentionDropDownTopRight({ top: element.pageY, right: element.pageX });
    }
  };

  const MentionClickHandle = (item) => {
    setFilteredSuggestionList(suggestionList);
    // Toggle the mention dropdown
    setHasMentionDropdown(true);
  };

  // onChange Text editor
  function onChange(e) {
    setMsg(e);
    let text = e;

    if (newsocket && text.length > 0) {
      handleCallTyping();
    } else if (newsocket && text.length === 0) {
      handleCallTypingOff();
    }
  }
  // channel and group member not exist
  useEffect(() => {
    let redirectFlag = false
    const isAdminUserPresent = () => {
      if (type === "userChatGroup" && groupMemberList) {
        const presentAdmin = groupMemberList.members?.some(
          (member) => member.user_type === "adminuser"
        );
        setIsAdminUser(presentAdmin); // Set the admin user presence in state
        return presentAdmin;
      }
    };

    const isUserPresent = () => {
      if (type === "userChatGroup" && groupMemberList?.members?.length > 0) {
        return groupMemberList.members.some(
          (member) => member.userId === userId
        );
      }
      if (type === "chatChannel" && groupMemberList?.membersList?.length > 0) {
        return groupMemberList.membersList.some(
          (member) => member._id === userId
        );
      }
    };

    if (Object.keys(groupMemberList).length > 0) {
      const adminPresent = isAdminUserPresent();
      const userPresent = isUserPresent();
      setIsUser(userPresent); // Update state to track user presence
      if (type === "chatChannel") {
        if (!userPresent) {
          setGroupMemberList({})
          redirectFlag = true
        }
      }
      // Redirect logic for "userChatGroup"
      if (type === "userChatGroup") {
        if (!userPresent) {
          setGroupMemberList({})
          redirectFlag = true
        }
      }
      // Ensure UI is updated
    }
    setHideDefault(false);
    if (redirectFlag) {
      navigate("/chats/me/me?notAccess");
    }
  }, [groupMemberList, type, userId, location.search]);


  const AttachmentClickHandle = (acceptedFiles, fileRejected) => {
    if (fileRejected.length) {
      setuploadErrFlag(true);
      setuploadErr("This file type is not supported");
    } else {
      let new_files = acceptedFiles.map((file) => file);
      let totalSize = new_files.reduce((acc, file) => acc + file.size, 0);

      for (let index = 0; index < new_files.length; index++) {
        if (new_files[index].size > 25000000) {
          // Check if any video file exceeds 25MB
          // bigfile = true;
          break;
        }
      }
      if (
        totalSize > 100000000 || // Check if total size exceeds 100MB
        [...files.images, ...files.videos, ...files.files, ...new_files]
          .length > 10
      ) {
        setuploadErrFlag(true);
        setuploadErr(
          totalSize > 100000000
            ? "Total files size must be smaller than 100MB"
            : "Maximum 10 files can be uploaded at a time"
        );
      } else {
        let newImagesArray = files.images;
        let newVideosArray = files.videos;
        let newFilesArray = files.files;
        let newImagesTempURLArray = filesURL.images;
        let newVideosTempURLArray = filesURL.videos;
        let newFilesTempURLArray = filesURL.files;

        new_files.map((newfile) => {
          if (newfile.type.indexOf("image") !== -1) {
            newImagesArray.push(newfile);
            newImagesTempURLArray.push(URL.createObjectURL(newfile));
          } else if (newfile.type.indexOf("video") !== -1) {
            newVideosArray.push(newfile);
            newVideosTempURLArray.push(URL.createObjectURL(newfile));
          } else {
            newFilesArray.push(newfile);
            newFilesTempURLArray.push({
              fileName: newfile.name,
              size: formatBytes(newfile.size),
            });
          }
        });
        setFiles({
          images: newImagesArray,
          videos: newVideosArray,
          files: newFilesArray,
        });
        setFilesURL({
          images: newImagesTempURLArray,
          videos: newVideosTempURLArray,
          files: newFilesTempURLArray,
        });
        setAttachmentData({
          images: newImagesTempURLArray,
          videos: newVideosTempURLArray,
        });
      }
    }
  };

  // function to handle reply close button click
  const MessageReplyHandleCloseClick = () => {
    setReplyMessageData({});
  };

  const handleCloseAttachment = (attachmentType, attachmentIndex) => {
    switch (attachmentType) {
      case "image":
        setFiles({
          ...files,
          images: files.images.filter(
            (item, index) => index !== attachmentIndex
          ),
        });
        setFilesURL({
          ...filesURL,
          images: filesURL.images.filter(
            (item, index) => index !== attachmentIndex
          ),
        });
        setAttachmentData({
          ...filesURL,
          images: filesURL.images.filter(
            (item, index) => index !== attachmentIndex
          ),
        });
        break;
      case "video":
        setFiles({
          ...files,
          videos: files.videos.filter(
            (item, index) => index !== attachmentIndex
          ),
        });
        setFilesURL({
          ...filesURL,
          videos: filesURL.videos.filter(
            (item, index) => index !== attachmentIndex
          ),
        });
        setAttachmentData({
          ...filesURL,
          videos: filesURL.videos.filter(
            (item, index) => index !== attachmentIndex
          ),
        });
        break;
      case "file":
        setFiles({
          ...files,
          files: files.files.filter((item, index) => index !== attachmentIndex),
        });
        setFilesURL({
          ...filesURL,
          files: filesURL.files.filter(
            (item, index) => index !== attachmentIndex
          ),
        });
        break;
      case "voice":
        setAudioBlobData();
        setRecordingDuration();
        break;
      default:
        break;
    }
  };
  const handleSelectEmoji = (event, emojiObject) => {
    setMsg((msg) => msg + event.emoji);
    setisemojiSelect(true);
  };

  const EmojiIconClickHandle = () => {
    setHasEmojiDropdown(!hasEmojiDropdown);
  };

  const handleOnClickVoiceMessage = () => {
    setIsVoiceMessageSelected(true);
  };

  const handleonClickCloseVoiceMessage = () => {
    setIsVoiceMessageSelected(false);
  };

  //handle click stop recording
  const handleClickStopRecording = (duration, audioBlob) => {
    if (audioBlob) {
      setAudioBlobData(audioBlob);
    }
    if (duration !== 0) {
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration % 60);

      // Use padStart to ensure two-digit format
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      setRecordingDuration(`${formattedMinutes}:${formattedSeconds}`);
    }
  };

  const closeDeleteModel = () => {
    setuploadErrFlag(false);
  };

  // Function to calculate total size of files
  const calculateTotalSize = (filesArray) => {
    return filesArray.reduce((acc, file) => acc + file.size, 0);
  };

  // Handle copy paste images
  const handleCopyPasteImage = (pastedImages) => {
    const newImagesArray = pastedImages.map((image) => image.blob);
    const newImagesTempURLArray = pastedImages.map((image) => image.imageUrl);

    // Calculate the new state based on the previous state
    setFiles((prevFiles) => {
      const combinedImages = [...prevFiles.images, ...newImagesArray];

      const combinedVideos = prevFiles.videos;
      const combinedFiles = prevFiles.files;

      // Calculate total size and count for all types of files
      const combinedFilesCount =
        combinedImages.length + combinedVideos.length + combinedFiles.length;
      const combinedFilesSize = calculateTotalSize([
        ...combinedImages,
        ...combinedVideos,
        ...combinedFiles,
      ]);

      // Check if the new state exceeds the limits
      const newTotalSize = calculateTotalSize(combinedImages);
      if (combinedFilesCount > 10 || combinedFilesSize > 100000000) {
        setuploadErrFlag(true);
        setuploadErr(
          newTotalSize > 100000000
            ? "Total files size must be smaller than 100MB"
            : "Maximum 10 files can be uploaded at a time"
        );
        return prevFiles; // Return the previous state to cancel the update
      }

      // If the limits are not exceeded, update the state
      setFilesURL((prevFilesURL) => ({
        ...prevFilesURL,
        images: [...prevFilesURL.images, ...newImagesTempURLArray],
        videos: prevFilesURL.videos,
        files: prevFilesURL.files,
      }));

      setAttachmentData((prevAttachmentData) => ({
        ...prevAttachmentData,
        images: [...prevAttachmentData.images, ...newImagesTempURLArray],
        videos: prevAttachmentData.videos,
      }));

      // Return the updated state
      return {
        ...prevFiles,
        images: combinedImages,
        videos: prevFiles.videos,
        files: prevFiles.files,
      };
    });
  };

  return (
    <>
      <ModelPopup
        isFooter={true}
        isHeader={true}
        modelSize="648"
        isOpen={uploadErrFlag}
        onClose={closeDeleteModel}
        headingLable="Failed to upload files"
        buttonText1="Close"
        buttonType="onebutton"
        handleClick2ndButton={closeDeleteModel}
      >
        <div className="channel-group-wrapper">
          <Typography variant="p">{uploadErr}</Typography>
        </div>
      </ModelPopup>
      {!hideDefault && type === "user" ? (
        <>
          <ChatMessageBar
            handleClickOnRepliedMessage={() => {
              handleClickOnRepliedMessage(replyMessageData._id);
            }}
            allUsersList={allUsersList}
            suggestionList={suggestionList}
            EmojiIconClickHandle={EmojiIconClickHandle}
            setHasEmojiDropdown={setHasEmojiDropdown}
            hasEmojiDropdown={hasEmojiDropdown}
            handleSelectEmoji={handleSelectEmoji}
            hasMentionDropdown={hasMentionDropdown}
            filteredSuggestionList={filteredSuggestionList}
            setHasMentionDropdown={setHasMentionDropdown}
            setSelectedMentionName={setSelectedMentionName}
            selectedMentionName={selectedMentionName}
            MentionClickHandle={MentionClickHandle}
            msg={msg}
            setMsg={setMsg}
            type="TextMessage"
            onChange={onChange}
            handleCallTyping={handleCallTyping}
            handleCallTypingOff={handleCallTypingOff}
            handleEnter={handleEnter}
            isTypingIndicator={
              alltyper[userid] &&
                alltyper[userid].username &&
                Object.keys(alltyper[userid].username).length > 0
                ? true
                : false
            }
            TypingIndicatorCount={
              alltyper[userid] && alltyper[userid].username
                ? Object.keys(alltyper[userid].username).length === 1
                  ? 0
                  : Object.keys(alltyper[userid].username).length === 2
                    ? 0
                    : Object.keys(alltyper[userid].username).length > 2
                      ? Object.keys(alltyper[userid].username).length
                      : 0
                : 0
            }
            TypingIndicatorMembersName={
              alltyper[userid] &&
                alltyper[userid].username &&
                Object.keys(alltyper[userid].username).length > 1
                ? alltyper[userid].username[
                Object.keys(alltyper[userid].username)[1]
                ]
                : ""
            }
            TypingIndicatorName={
              alltyper[userid] &&
                alltyper[userid].username &&
                Object.keys(alltyper[userid].username).length
                ? alltyper[userid].username[
                Object.keys(alltyper[userid].username)[0]
                ]
                : ""
            }
            TypingIndicatorType={
              alltyper[userid] && alltyper[userid].username
                ? Object.keys(alltyper[userid].username).length === 1
                  ? "name"
                  : Object.keys(alltyper[userid].username).length === 2
                    ? "membersName"
                    : Object.keys(alltyper[userid].username).length > 2
                      ? "count"
                      : ""
                : ""
            }
            AttachmentClickHandle={AttachmentClickHandle}
            isMessageBarAttachments={
              filesURL.images.length ||
                filesURL.videos.length ||
                filesURL.files.length ||
                audioBlobData
                ? true
                : false
            }
            isVoiceMessageSelected={isVoiceMessageSelected}
            hasImagesattachment={filesURL.images.length ? true : false}
            hasVideosattachment={filesURL.videos.length ? true : false}
            hasFilesattachment={filesURL.files.length ? true : false}
            hasVoiceattachment={audioBlobData ? true : false}
            MessageBarAttachmentsImagesSrc={
              filesURL.images.length ? filesURL.images : []
            }
            MessageBarAttachmentsVideosSrc={
              filesURL.videos.length ? filesURL.videos : []
            }
            MessageBarAttachmentsFiles={
              filesURL.files.length ? filesURL.files : []
            }
            audioBlobData={audioBlobData}
            MessageBarAttachmentsAlt="images"
            MessageBarAttachmentsDuration={recordingDuration}
            handleCloseAttachment={handleCloseAttachment}
            isMessageBarReply={
              replyMessageData && Object.keys(replyMessageData).length
                ? true
                : false
            }
            MessageBarReplyType={
              replyMessageData && replyMessageData.message_type === "image"
                ? "ImageReply"
                : replyMessageData && replyMessageData.message_type === "video"
                  ? "VideoReply"
                  : replyMessageData &&
                    replyMessageData.message_type === "document"
                    ? "FileReply"
                    : replyMessageData && replyMessageData.message_type === "voice"
                      ? "VoiceReply"
                      : "MessageReply"
            }
            MessageBarReplySrc={
              replyMessageData &&
              ((replyMessageData.message_type === "image" &&
                replyMessageData.src[0]) ||
                (replyMessageData.message_type === "video" &&
                  replyMessageData.videos[0]))
            }
            MessageBarReplyAvtarName={
              replyMessageData ? replyMessageData.sender?.firstname : ""
            }
            MessageBarReplyReplyText={
              replyMessageData &&
                replyMessageData.message &&
                replyMessageData.message.length
                ? replyMessageData.message
                : replyMessageData &&
                  replyMessageData.message_type === "document"
                  ? replyMessageData.files.fileName
                  : ""
            }
            MessageBarReplyAlt="images"
            MessageReplyHandleCloseClick={MessageReplyHandleCloseClick}
            handleOnClickVoiceMessage={handleOnClickVoiceMessage}
            handleonClickCloseVoiceMessage={handleonClickCloseVoiceMessage}
            handleClickStopRecording={handleClickStopRecording}
            handleMentionDropDownPosition={handleMentionDropDownPosition}
            mentionDropDownTopRight={mentionDropDownTopRight}
            handlePreviewClick={handlePreviewClick}
            editMessage={editMessage}
            setisemojiSelect={setisemojiSelect}
            isemojiOpenSelect={isemojiOpenSelect}
            sendBtnClick={sendBtnClick}
            setSendBtnClick={setSendBtnClick}
            editMessageState={editMessageState}
            setEditMessageState={setEditMessageState}
            editorClear={editorClear}
            setEditorClear={setEditorClear}
            uploadErrFlag={uploadErrFlag}
            handleCopyPasteImage={handleCopyPasteImage}
          />
          <Dropzone
            onDragLeave={(e) => {
              e.preventDefault();
              setdrag(false);
            }}
            onDrop={(acceptedFiles, fileRejected) => {
              setdrag(false);
              AttachmentClickHandle(acceptedFiles, fileRejected);
            }}
            multiple={true}
            accept="image/png, image/gif, image/jpeg, image/jpg, video/mp4, video/mov, video/gif, video/webm, image/heic, video/avi, application/vnd.ms-excel, application/pdf, application/msword, text/csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          >
            {({ getRootProps, getInputProps }) => (
              <div
                style={drag ? { display: "flex" } : { display: "none" }}
                {...getRootProps({
                  className: "chat-drop-file-container",
                })}
              >
                <input {...getInputProps()} />
                <span className="DropZoneClass">Drop files here to attach</span>
              </div>
            )}
          </Dropzone>
        </>
      ) : (isAdminUser && isUser && type === "userChatGroup") ||
        (type === "chatChannel" && isUser) ? (
        <>
          <ChatMessageBar
            setisemojiSelect={setisemojiSelect}
            isemojiOpenSelect={isemojiOpenSelect}
            handleClickOnRepliedMessage={() => {
              handleClickOnRepliedMessage(replyMessageData._id);
            }}
            allUsersList={allUsersList}
            suggestionList={suggestionList}
            EmojiIconClickHandle={EmojiIconClickHandle}
            setHasEmojiDropdown={setHasEmojiDropdown}
            hasEmojiDropdown={hasEmojiDropdown}
            handleSelectEmoji={handleSelectEmoji}
            hasMentionDropdown={hasMentionDropdown}
            filteredSuggestionList={filteredSuggestionList}
            setHasMentionDropdown={setHasMentionDropdown}
            setSelectedMentionName={setSelectedMentionName}
            selectedMentionName={selectedMentionName}
            MentionClickHandle={MentionClickHandle}
            msg={msg}
            setMsg={setMsg}
            type="TextMessage"
            onChange={onChange}
            handleCallTyping={handleCallTyping}
            handleCallTypingOff={handleCallTypingOff}
            handleEnter={handleEnter}
            isTypingIndicator={
              alltyper[userid] &&
                alltyper[userid].username &&
                Object.keys(alltyper[userid].username).length > 0
                ? true
                : false
            }
            TypingIndicatorCount={
              alltyper[userid] && alltyper[userid].username
                ? Object.keys(alltyper[userid].username).length === 1
                  ? 0
                  : Object.keys(alltyper[userid].username).length === 2
                    ? 0
                    : Object.keys(alltyper[userid].username).length > 2
                      ? Object.keys(alltyper[userid].username).length
                      : 0
                : 0
            }
            TypingIndicatorMembersName={
              alltyper[userid] &&
                alltyper[userid].username &&
                Object.keys(alltyper[userid].username).length > 1
                ? alltyper[userid].username[
                Object.keys(alltyper[userid].username)[1]
                ]
                : ""
            }
            TypingIndicatorName={
              alltyper[userid] &&
                alltyper[userid].username &&
                Object.keys(alltyper[userid].username).length
                ? alltyper[userid].username[
                Object.keys(alltyper[userid].username)[0]
                ]
                : ""
            }
            TypingIndicatorType={
              alltyper[userid] && alltyper[userid].username
                ? Object.keys(alltyper[userid].username).length === 1
                  ? "name"
                  : Object.keys(alltyper[userid].username).length === 2
                    ? "membersName"
                    : Object.keys(alltyper[userid].username).length > 2
                      ? "count"
                      : ""
                : ""
            }
            AttachmentClickHandle={AttachmentClickHandle}
            isMessageBarAttachments={
              filesURL.images.length ||
                filesURL.videos.length ||
                filesURL.files.length ||
                audioBlobData
                ? true
                : false
            }
            isVoiceMessageSelected={isVoiceMessageSelected}
            hasImagesattachment={filesURL.images.length ? true : false}
            hasVideosattachment={filesURL.videos.length ? true : false}
            hasFilesattachment={filesURL.files.length ? true : false}
            hasVoiceattachment={audioBlobData ? true : false}
            MessageBarAttachmentsImagesSrc={
              filesURL.images.length ? filesURL.images : []
            }
            MessageBarAttachmentsVideosSrc={
              filesURL.videos.length ? filesURL.videos : []
            }
            MessageBarAttachmentsFiles={
              filesURL.files.length ? filesURL.files : []
            }
            audioBlobData={audioBlobData}
            MessageBarAttachmentsAlt="images"
            MessageBarAttachmentsDuration={recordingDuration}
            handleCloseAttachment={handleCloseAttachment}
            isMessageBarReply={
              replyMessageData && Object.keys(replyMessageData).length
                ? true
                : false
            }
            MessageBarReplyType={
              replyMessageData && replyMessageData.message_type === "image"
                ? "ImageReply"
                : replyMessageData && replyMessageData.message_type === "video"
                  ? "VideoReply"
                  : replyMessageData &&
                    replyMessageData.message_type === "document"
                    ? "FileReply"
                    : replyMessageData && replyMessageData.message_type === "voice"
                      ? "VoiceReply"
                      : "MessageReply"
            }
            MessageBarReplySrc={
              replyMessageData &&
              ((replyMessageData.message_type === "image" &&
                replyMessageData.src[0]) ||
                (replyMessageData.message_type === "video" &&
                  replyMessageData.videos[0]))
            }
            MessageBarReplyAvtarName={
              replyMessageData ? replyMessageData.sender?.firstname : ""
            }
            MessageBarReplyReplyText={
              replyMessageData &&
                replyMessageData.message &&
                replyMessageData.message.length
                ? replyMessageData.message
                : replyMessageData &&
                  replyMessageData.message_type === "document"
                  ? replyMessageData.files.fileName
                  : ""
            }
            MessageBarReplyAlt="images"
            MessageReplyHandleCloseClick={MessageReplyHandleCloseClick}
            handleOnClickVoiceMessage={handleOnClickVoiceMessage}
            handleonClickCloseVoiceMessage={handleonClickCloseVoiceMessage}
            handleClickStopRecording={handleClickStopRecording}
            handleMentionDropDownPosition={handleMentionDropDownPosition}
            mentionDropDownTopRight={mentionDropDownTopRight}
            handlePreviewClick={handlePreviewClick}
            editMessage={editMessage}
            // isemojiOpenSelect={isemojiOpenSelect}
            sendBtnClick={sendBtnClick}
            setSendBtnClick={setSendBtnClick}
            editMessageState={editMessageState}
            setEditMessageState={setEditMessageState}
            editorClear={editorClear}
            setEditorClear={setEditorClear}
            uploadErrFlag={uploadErrFlag}
            handleCopyPasteImage={handleCopyPasteImage}
          />
          <Dropzone
            onDragLeave={(e) => {
              e.preventDefault();
              setdrag(false);
            }}
            onDrop={(acceptedFiles, fileRejected) => {
              setdrag(false);
              AttachmentClickHandle(acceptedFiles, fileRejected);
            }}
            multiple={true}
            accept="image/png, image/gif, image/jpeg, image/jpg, video/mp4, video/mov, video/gif, video/webm, image/heic, video/avi, application/vnd.ms-excel, application/pdf, application/msword, text/csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          >
            {({ getRootProps, getInputProps }) => (
              <div
                style={drag ? { display: "flex" } : { display: "none" }}
                {...getRootProps({
                  className: "chat-drop-file-container",
                })}
              >
                <input {...getInputProps()} />
                <span className="DropZoneClass">Drop files here to attach</span>
              </div>
            )}
          </Dropzone>
        </>
      ) : (
        groupMemberList !== undefined &&
        !hideDefault && (
          <div className="leave-group-message">
            {!isUser &&
              groupMemberList?.membersList?.length > 0 &&
              type === "chatChannel" &&
              `You can't send message to this channel because you're no longer a member`}
            {!isUser &&
              isAdminUser &&
              type === "userChatGroup" &&
              `You can't send message to this group because you're no longer a member`}
            {isUser &&
              !isAdminUser &&
              type === "userChatGroup" &&
              "Group admin has removed from Group!"}
          </div>
        )
      )}
    </>
  );
}
